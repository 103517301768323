import Vue from 'vue'
import App from './App.vue'
import routes from './router'
import VueRouter from "vue-router";
import "./style/index.scss";
import "swiper/dist/css/swiper.min.css";
import './components/icons/index.js'
import yourMethod from '@/common/gtp.js'

if (window.ethereum) {
  window.web3 = new Web3(window.ethereum);
} else if (window.web3) {
  window.web3 = new Web3(web3.currentProvider);
}

// 复制
import VueClipBoard from 'vue-clipboard2'
Vue.use(VueClipBoard);


//控制台
// import './vconsole'
 
// vuex
import store from './store'
Vue.prototype.$store = store

//引入web3方法
import Web3 from 'web3'
Vue.prototype.$Web3 = Web3

//全局引入vantUI组件
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

Vue.use(yourMethod)
//导航组件
import Navbar from "@/components/common/newNav.vue"
Vue.component('navbar', Navbar)

//过滤器
import * as filters from "@/common/filter.js";
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

//请求
import api from "./api";
import http from "./api/request";
Vue.prototype.$axios = http;
Vue.prototype.$api = api;

//引入语言包
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
// 注册i18n实例并引入语言文件
const i18n = new VueI18n({
  locale: 'en', // 定义默认语言为英文
  messages: {
    'zh': require('@/language/zh.json'),
    'en': require('@/language/en.json'),
  },
  // 隐藏警告
  silentTranslationWarn: true
})

// 一款好用的本地存储插件
import storage from "storejs"
Vue.prototype.$storage = storage

//粘贴板
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

Vue.use(VueRouter)
const router = new VueRouter({
  routes,
  // mode: routerMode,
  // hashbang: false,
  // history: true,
  strict: process.env.NODE_ENV !== "production",
  scrollBehavior(to, from, savedPosition) {
    // console.log(to, from, savedPosition)
    if (savedPosition) {
      return savedPosition;
    } else {
      if (from.meta.keepAlive) {
        from.meta.savedPosition = document.body.scrollTop;
      }
      return { x: 0, y: to.meta.savedPosition || 0 };
    }
  },
});
// Vue.prototype.$router = routes

// 解决IOS上输入和滑动冲突
window.document.ontouchmove = (e) => {
  document.querySelectorAll("input").forEach((e) => {
    e.blur();
  });
  document.querySelectorAll("textarea").forEach((e) => {
    e.blur();
  });
};

//引入moment
import moment from 'moment';
Vue.prototype.$moment = moment

Vue.config.productionTip = false

const myVue = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

 export default myVue
