import store from '../store'
import storage from "storejs"

export default {
    installed: false,// 初始值 false 表示尚未插》
    install(Vue) {
        if (this.installed) return;
        this.installed = true
        Vue.prototype.$yourMethod = function () {
            store.commit("setwalletAddress", "");
            store.commit("devotokens", "");
            storage.remove("devotokens");
            store.commit("setLinkBsc", false);
            storage.clear();
            // console.log('执行一次的方法')
        } 
    },
    
}