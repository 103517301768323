<template>
  <van-sticky>
    <div class="navbar d-flex ai-center jc-between">
      <div class="d-flex ai-center">
        <van-image
          width="47px"
          height="47px"
          :src="require('@/assets/xxImage/index/logo@2x.png')"
          style="margin-right: 19px"
          @click="toindex"
        />
        <van-image
          width="23px"
          height="19px"
          :src="require('@/assets/cls/aside-btn.png')"
          @click="asideShow"
        />
      </div>
      <div class="d-flex ai-center">
        <div @click="toShow">
          <!-- 切换语言 -->
          <van-popover
            v-model="showLang"
            trigger="click"
            :actions="langList"
            @select="onSelectLang"
          >
            <template #reference>
              <div class="right_lang d-flex ai-center jc-around">
                <van-image
                  width="26px"
                  height="26px"
                  :src="langList[langIndex].icon"
                />
              </div>
            </template>
          </van-popover>
        </div>

        <!-- 已连接 -->
        <van-popover
          v-model="showConnect"
          trigger="click"
          :actions="isLinkBsc ? connectList : notconnectList"
          @select="onSelect"
          v-if="walletAddress && devotokens"
        >
          <template #reference>
            <div class="metamask d-flex ai-center jc-around px-5">
              <van-image
                width="15px"
                height="14px"
                :src="require('@/assets/cls/connect.png')"
              />
              <span class="m-l-5 fs-md mx-5">
                {{ walletAddress | plusXing(2, 5) }}
              </span>
              <van-image
                width="8px"
                height="6px"
                :src="require('@/assets/arrow@2x.png')"
              />
              <!-- <van-image
                width="8px"
                height="6px"
                :src="require('@/assets/arrow@2x.png')"
                v-if="isLinkBsc"
              /> -->
              <!-- <van-image width="12px" height="12px" v-else :src="require('@/assets/info@2x.png')" /> -->
            </div>
          </template>
        </van-popover>
        <!-- 未连接 -->
        <!-- <div
            class="right_connect d-flex ai-center jc-center px-10 bg-bule"
            v-else
            @click="ConnectWallet()"
          >
            <van-image
              width="15px"
              height="7px"
              :src="require('@/assets/link@2x.png')"
            />
            <span class="m-l-3 fs-md">{{ $t("home.connect") }}</span>
        </div>-->
        <van-button
          v-else
          @click="ConnectWallet()"
          class="bold connect-btn"
          style="margin-left: 23px"
          color="#00A8FF"
          size="small"
        >
          <van-image
            width="17px"
            height="17px"
            :src="require('@/assets/cls/connect-btn.png')"
          />
          <span style="margin-left: 9px">{{ $t("home.connect") }}</span>
        </van-button>
      </div>
    </div>
  </van-sticky>
</template>
  
  <script>
import { mapState } from "vuex";
import mixin from "../../common/web3";
export default {
  name: "NAVBAR",
  mixins: [mixin],
  inject: ["reload"],
  data() {
    return {
      title: "",
      langIndex: 0,
      showLang: false, //选择语言下拉框
      showConnect: false, //连接钱包成功后用户下拉框
      langList: [
        {
          index: 0,
          text: "English",
          icon: require("@/assets/en@2x.png"),
        },
        {
          index: 1,
          text: "繁体中文",
          icon: require("@/assets/zh@2x.png"),
        },
      ],
      // devotokens: "",
    };
  },
  computed: {
    //vuex取值
    ...mapState({
      walletAddress: (state) => state.walletAddress,
      isLinkBsc: (state) => state.isLinkBsc,
    }),
    devotokens() {
      return this.$storage.get("devotokens");
    },
    lang() {
      return this.$storage.get("lang");
    },
    connectList() {
      return [
        {
          index: 1,
          text: `${this.$t("index.disconnect")}`,
          icon: require("@/assets/cls/break.png"),
        },
      ];
    },
    notconnectList() {
      return [
        {
          index: 1,
          text: `${this.$t("home.sedao")}`,
          icon: require("@/assets/xxImage/index/duankai.png"),
        },
      ];
    },
  },
  mounted() {
    this._i18n.locale = this.$storage.get("lang");
    this.langIndex = this.lang == "zh" ? 1 : 0;

    window.addEventListener("beforeunload", () => this.beforeunloadHandler());
    window.addEventListener("unload", () => this.unloadHandler());

    //添加监听事件
    // window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
  },

destroyed() {
  window.removeEventListener("beforeunload", () => this.beforeunloadHandler())
  window.removeEventListener("unload", () => this.unloadHandler())
},
  methods: {
    beforeunloadHandler() {
      this.beforeUnloadTime = new Date().getTime();
    },
    unloadHandler() {
      this.gapTime = new Date().getTime() - this.beforeUnloadTime;
      if (this.gapTime <= 2) {
        //判断是窗口关闭还是刷新，小于5代表关闭，否则就是刷新。
        if (window.localStorage.switchMT == 2) {
          window.localStorage.clear();
        }
      }
    },
    asideShow() {
      this.$emit("asideShow", true);
    },
    leftClick() {
      if (this.isGoBack) {
        this.$router.back();
      } else {
        this.$emit("leftClick");
      }
    },
    rightClick() {
      this.$emit("rightClick");
    },
    toShow() {
      this.$emit("numChange");
    },
    //选择语言
    onSelectLang(e) {
      // console.log(652)
      this.$emit("numChange", false);
      if (e.index == 0 || e.index == 1) {
        this.langIndex = e.index;
        this._i18n.locale = e.index == 0 ? "en" : "zh";
        this.$storage.set("lang", e.index == 0 ? "en" : "zh");
        this.$store.commit("setLang", e.index == 0 ? "en" : "zh");
        this.reload();
      } else {
        this.$toast(this.$t("index.ipwarm"));
      }
    },
    //连接钱包成功后的选择
    onSelect(e) {
      // console.log(e);
      if (this.isLinkBsc) {
        //已登入BSC
        if (e.index == 0) {
          this.$router.push("/myNFTcard");
        }
      }
      if (e.index == 1) {
        //退出
        this.$store.commit("setwalletAddress", "");
        this.$store.commit("setDevotokens", "");
        this.$storage.remove("devotokens");
        this.$store.commit("setLinkBsc", false);
        this.$storage.clear();
        this.reload();
        //退出钱包后 跳到首页
        setTimeout(() => {
          this.$router.push("/index");
        }, 1000);
      }
    },
    //连接钱包
    ConnectWallet() {
      this.getInfoData();
      // this.connectMetaMask();
    },
    toindex() {
      // this.getToto()
      this.$router.push("/index");
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.navbar {
  padding: 15px 16px;
  background: linear-gradient(90deg, #b79afa, #8867fe);
  // background: url("../../assets/cls/nav-bg.png") no-repeat;
  // background-color:#7857FE ;
  background-size: 100% 100%;
}
.right_connect {
  height: 30px;
  border-radius: 24px;
}
.right_lang {
  height: 32px;
  .lang {
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #ffffff;
  }
}
.metamask {
  background: linear-gradient(90deg, #b79afa, #8867fe) !important;
  border: 1px solid #000000 !important;
  border-radius: 5px !important;

  height: 32px;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
  margin-left: 21px;
}
.connect-btn {
  // width: 86px;
  // height: 29px;
  background: linear-gradient(90deg, #b79afa, #8867fe) !important;
  border: 1px solid #000000 !important;
  border-radius: 5px !important;

  .van-button__text {
    display: flex;
    align-items: center;
  }
}
</style>
  