const routers = [
  {
    path: "/index",
    name: "index",
    component: () => import("@/views/index.vue")
  },
  // 邀请
  {
    path: "/invite",
    name: "invite",
    component: () => import("@/views/invite.vue")
  },
  // 个人中心
  {
    path: "/personal",
    name: "personal",
    component: () => import("@/views/personal.vue")
  },
  // 节点权益
  {
    path: "/rights",
    name: "rights",
    component: () => import("@/views/rights.vue")
  },
  // 活动记录
  {
    path: "/activeRecord",
    name: "activeRecord",
    component: () => import("@/views/activeRecord.vue")
  },
  // nft
  {
    path: "/nft",
    name: "nft",
    component: () => import("@/views/nft.vue")
  },
  // 节点
  {
    path: "/node",
    name: "node",
    component: () => import("@/views/node.vue")
  },
  // 钱包资产
  {
    path: "/property",
    name: "property",
    component: () => import("@/views/property.vue")
  },
  //答题
  {
    path: "/answer",
    name: "answer",
    component: () => import("@/views/answer.vue")
  },
  //提现
  {
    path: "/withdraw",
    name: "withdraw",
    component: () => import("@/views/withdraw.vue")
  },
  //答题闯关
  {
    path: "/answerPage",
    name: "answerPage",
    component: () => import("@/views/answerPage.vue")
  },
  //答题挑战
  {
    path: "/answerChallenge",
    name: "answerChallenge",
    component: () => import("@/views/answerChallenge.vue")
  },
  //挖矿
  {
    path: "/mining",
    name: "mining",
    component: () => import("@/views/mining.vue")
  },
  //立即交易
  {
    path: "/deal",
    name: "deal",
    component: () => import("@/views/deal.vue")
  },
  //兑换记录
  {
    path: "/exchangeRecord",
    name: "exchangeRecord",
    component: () => import("@/views/exchangeRecord.vue")
  },
  //质押
  {
    path: "/pledge",
    name: "pledge",
    component: () => import("@/views/pledge.vue")
  },
    //我的分享
    {
      path: "/myShare",
      name: "myShare",
      component: () => import("@/views/myShare.vue")
    },

  

  //Gamefi市场
  {
    path: "/gamefi",
    name: "gamefi",
    component: () => import("@/views/gamefi.vue")
  },
  //游戏详情
  {
    path: "/gameDetail",
    name: "gameDetail",
    component: () => import("@/views/gameDetail.vue")
  },
  //nft详情
  {
    path: "/nftDetail",
    name: "nftDetail",
    component: () => import("@/views/nftDetail.vue")
  },
  //购买记录
  {
    path: "/buyRecord",
    name: "buyRecord",
    component: () => import("@/views/buyRecord.vue")
  },
  //Gamefi资讯
  {
    path: "/news",
    name: "news",
    component: () => import("@/views/news.vue")
  },
  //资讯详情
  {
    path: "/newsDetail",
    name: "newsDetail",
    component: () => import("@/views/newsDetail.vue")
  },
  //卡牌商城
  {
    path: "/cardStore",
    name: "cardStore",
    component: () => import("@/views/cardStore.vue")
  },
  //卡牌详情
  {
    path: "/cardDetail",
    name: "cardDetail",
    component: () => import("@/views/cardDetail.vue")
  },
  //盲盒
  {
    path: "/bindBox",
    name: "bindBox",
    component: () => import("@/views/bindBox.vue")
  },
  //我的NFT卡牌
  {
    path: "/myNFTcard",
    name: "myNFTcard",
    component: () => import("@/views/myNFTcard.vue")
  },
  //我的卡牌详情
  {
    path: "/mycardDetail",
    name: "mycardDetail",
    component: () => import("@/views/mycardDetail.vue")
  },
  //收矿
  {
    path: "/collectMine",
    name: "collectMine",
    component: () => import("@/views/collectMine.vue")
  },
  //合成卡牌
  {
    path: "/synthetic",
    name: "synthetic",
    component: () => import("@/views/synthetic.vue")
  },
  //质押记录，交易记录，合成记录
  {
    path: "/record",
    name: "record",
    component: () => import("@/views/record.vue")
  },
  //提取
  {
    path: "/extract",
    name: "extract",
    component: () => import("@/views/extract.vue")
  },
  //提取记录/收益记录
  {
    path: "/extractRecord",
    name: "extractRecord",
    component: () => import("@/views/extractRecord.vue")
  },
  //转账
  {
    path: "/accounts",
    name: "accounts",
    component: () => import("@/views/transfer_accounts.vue")
  },
  //转账记录
  {
    path: "/accountsRecord",
    name: "accountsRecord",
    component: () => import("@/views/accountsRecord.vue")
  },
  //活动记录
  {
    path: "/activities",
    name: "activities",
    component: () => import("@/views/activities.vue")
  },
]

export default routers;