import http from "../../api/request"

//网站配置
export const getSetting = (params) => http.get("/api/website/setting", params);
//卡牌收益
export const getcardearnSet = (params) => http.get("/api/website/earnSet", params);
//我的卡牌
export const getusercards = (params) => http.get("/api/user/cards", params);
//我的用户信息
export const getUserinfo = (params) => http.get("/api/user/info", params);
