export default {
  Access: '/api/auth/access', //metamask登录
  inviter_code: '/api/user/inviter_code', //邀请链接
  myEarn: '/api/earnings/myEarn', //用户资产列表
  setting: '/api/website/setting', //网站配置
  recharge: '/api/user/recharge', //usdt充值
  market: '/api/website/market', //首页行情
  userInfo: '/api/user/info', //获取用户信息
  pledgeList: '/api/pledge/list', //获取用户质押的订单
  cancelAuto: '/api/pledge/cancelAuto', //自动复投
  redeem: '/api/pledge/redeem', //赎回质押
  clsPledge: '/api/pledge/pledge', //质押
  activities: '/api/user/activities', //活动记录
  get_nft_List: '/api/user/get_nft_List', //获取NFT列表和用户等级
  buy_nft: '/api/user/buy_nft', //购买nft等级
  buy_node: '/api/user/buy_node', //购买全球节点
  subsidy: '/api/user/subsidy', //工作室补贴申请
  withdraw: '/api/user/withdraw', //提取
  exchange: '/api/user/exchange', //兑换
  tiqu: '/api/user/tiqu', //提取cls
  invite_list: '/api/user/invite_list', //我的邀请列表
  redeemSign: '/api/pledge/redeemSign', //赎回签名
  withdrawSign: '/api/user/withdrawSign', //提现签名

  getmsg: '/api/auth/msg', //获取登录签名信息
  userinfo: '/api/user/info', //用户信息
  banner: '/api/home/banner', //首页轮播图
  homeCardsList: '/api/home/cards/top', //商城首页卡片订单  
  usercards: '/api/user/cards', //获取用户卡片列表 
  drawbox: '/api/card/draw', //抽盲盒
  carddetail: '/api/card/detail', //卡片详情
  cardearnSet: '/api/website/earnSet', //卡牌收益
  mallCards: '/api/mall/cards', //商城卡片订单分页列表  卡牌商城
  checkDraw: '/api/card/checkDraw', //盲盒卡片检测
  cardrecord: '/api/card/record', //盲盒记录
  cardbuy: '/api/card/buy', //买卡片 预备
  cardbuyback: '/api/card/buyback', //买卡片 回调
  withdraw_record: '/api/user/withdraw_record', //提币记录
  earnrecord: '/api/earnings/record', //收益记录
  pledgerecord: '/api/pledge/record', //质押记录/出售记录
  carddetail: '/api/card/detail', //卡片详情
  cardredeem: '/api/pledge/redeem', //赎回卡片
  pledge: '/api/pledge/pledge', //质押卡片 预备
  pledgeCallBack: '/api/pledge/pledgeCallBack', //质押卡片 回调
  cardsell: '/api/card/sell', //卖卡片 预备
  cardsellback: '/api/card/sellback', //卖卡片 回调
  cardcancel: '/api/card/cancel', //取消卖卡片 预备
  cardcancelback: '/api/card/cancelback', //取消卖卡片 回调

  gamefilist: '/api/gamefi/list', //游戏列表
  gamefinftList: '/api/gamefi/nft/list', //游戏详情
  gamefinftdetail: '/api/gamefi/nft/detail', //nft详情
  gamefinftbuy: '/api/gamefi/nft/buy', //购买nft
  newsType: '/api/gamefi/newsType', //资讯分类
  newslist: '/api/gamefi/news/list', //资讯列表
  newsdetail: '/api/gamefi/news/detail', //资讯详情
  merge_card_list: '/api/pledge/merge_card_list', //合成卡片列表
  merge: '/api/pledge/merge', //合成预备
  mergeCallBack: '/api/pledge/mergeCallBack', //合成回调
  merge_check: '/api/pledge/merge_check', //合成结果检测
  one_collect: '/api/pledge/one_collect', //收矿
  dig_list: '/api/pledge/dig_list', //收矿列表 
  buyLog: '/api/gamefi/nft/buyLog', //购买记录 
  mergeList: '/api/pledge/mergeList', //合成记录

  getpickaxe:'/api/pickaxe/list', //获取镐头数据
  activation:'/api/user/activation',// 激活用户
  pickaxe:'api/pickaxe/list', //稿头列表
  challenge:'/api/challenge/list',//获取今日挑战答题题目列表
  breakinto:'/api/breakinto/list', //获取今日闯关答题题目列表
  mineActivity:'/api/mine/activity', //挖矿活动记录 
  userRecharge:'/api/user/recharge', //充值回调 
  answerActivity:'/api/answer/activity', //答题活动记录 
  inviterActiveity:"/api/inviter/activity", //邀请活动记录
  transferList:'/api/transfer/list', //互转记录 
  answerSchedule:'/api/answer/schedule', //获取用户答题进度 
  answerChallenge:'/api/answer/challenge', //挑战答题结果 
  answerQuestions:'/api/answer/questions', //用户答题闯关进度记录
  pledgeRedeemSign:'/api/pledge/redeemSign', //赎回签名
  // userWithdraw:'/api/user/withdraw', //提现
  userWithdraw:'/api/token/Interchange',//代币互转 
  // pledgeCancelAuto:'/api/pledge/cancelAuto', //用户取消或开启自动复投 
  minecartClean:'/api/minecart/clean', //用户清空矿车 
  mineStart:'/api/mine/start', //开始挖矿 
  useAutocard:'/api/use/autocard', //用户使用自动挖矿卡 
  useMineCart:'/api/use/mineCart', //用户使用矿车 
  usePrick:'/api/use/prick', //用户使用镐头 
  userMineCart:'/api/user/mineCart', //用户矿车数据 
  buyMineCart:'/api/buy/mineCart', //购买矿车 
  userPick:'/api/user/pick', //用户镐头数据 
  userMine:'/api/user/mine', //获取用户挖矿数据
  buyPick: '/api/buy/pick', //购买镐头 
  pledgeLisst:'/api/pledge/pledgeList', //质押活动记录
  // userInviter_code:'/api/user/inviter_code', //邀请链接
  activationUsdt:'/api/activation/usdt', //激活扣U回调 
  // userActivation:'/api/user/activation', //激活用户 
  // authMsg:'/api/auth/msg',//获取登录签名信息 
  // authAccess:'/api/auth/access', //metamask登录注册
};