import Vue from 'vue';
import Vuex from 'vuex';
import storage from "storejs"
import createPersistedState from 'vuex-persistedstate'
import { getSetting, getcardearnSet, getusercards, getUserinfo } from "../api/common/index.api"
import Web3 from "web3"
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    walletAddress: '',//钱包地址
    isLinkBsc: false, //是否登入BSC
    devotokens: "",   //签名token
    lang: 'en',
    setting: {},
    userinfo: {},
    balance: null,
    cardEarn: [],
    cardList: [],
    totalAmount:'',//总资产
  },
  mutations: {
    //设置钱包地址
    setwalletAddress(state, params) {
      state.walletAddress = params;
    },
    settotalAmount(state, params) {//设置总资产
      state.totalAmount = params;
    },
    setLinkBsc(state, params) {
      state.isLinkBsc = params;
    },
    setDevotokens(state, params) {
      state.devotokens = params;
    },
    setLang(state, params) {
      state.lang = params;
    },
    setSetting(state, value) {
      state.setting = value
    },
    setUserinfo(state, value) {
      state.userinfo = value
    },
    setBalance(state, value) {
      state.balance = value
    },
    setCardEarn(state, value) {
      state.cardEarn = value
    },
    setcardList(state, value) {
      state.cardList = value
    },
  },
  actions: {
    // 获取网站配置
    getSetting({ commit }, state) {
      getSetting().then(res => {
        if (res.code == 200) {
          commit('setSetting', JSON.stringify(res.data))
        }
      })
    },
    //用户信息
    getUserinfo({ commit }, state) {
      getUserinfo().then(res => {
        if (res.code == 200) {
          commit('setUserinfo', JSON.stringify(res.data))
        }
      })
    },
    //钱包余额  BNB
    async getBalance({ commit }, state) {
      window.web3 = new Web3(window.ethereum)
      web3.eth.getAccounts().then((account) => {
        web3.eth.getBalance(account[0], ('latest')).then((res) => {//获取当前账户余额  //eth_getBalance
          // console.log(res / Math.pow(10, 18));
          let walletBalance = res / Math.pow(10, 18)
          commit('setBalance', walletBalance)
        }).catch(err => {
          commit('setBalance', null)
        })
      });
    },
    //卡牌收益
    getCardEarn({ commit }, state) {
      getcardearnSet().then((res) => {
        if (res.code == 200) {
          commit('setCardEarn', JSON.stringify(res.data))
        }
      });
    },
    //我的卡牌
    // getUserCard({ commit }, state) {
    //   getusercards({ page: 1, page_size: 10 }).then((res) => {
    //     commit('setcardList', res.data.list)
    //     this.$storage.set('cardList', JSON.stringify(res.data.list))
    //   });
    // },
  },
  // plugins: [createPersistedState()] //存储到localStorage里
  plugins: [createPersistedState({
    storage: window.sessionStorage,
    reducer(data) {
      return {
        // 设置只储存state中的myData
        userinfo: data.userinfo,
        setting: data.setting,
        walletAddress: data.walletAddress,
        cardEarn: data.cardEarn,
        balance: data.balance
      }
    }
  })
  ]
});
export default store;