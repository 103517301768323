// import erc20 from "./erc20.json"
import ethUtil from 'ethereumjs-util'
import Web3 from "web3";
import storage from "storejs"
import { Toast } from 'vant';
import { ethers } from "ethers";
// import VConsole from "../vconsole.js";

export default {
    inject: ['reload'],
    data() {
        return {
            networkVersion: null,  //56正式  97测试
            show: true,
        }
    },
    async created() {
        // if (process.env.NODE_ENV === 'production') {
        //     /*第二层if，根据.env文件中的VUE_APP_FLAG判断是生产环境还是测试环境*/
        //     if (process.env.VUE_APP_FLAG === 'pro') {
        //         //production 生产环境 线上
        //         this.networkVersion = 56
        //     } else {
        //         //test 测试环境
        //         this.networkVersion = 56
        //     }
        // } else {
        //     //serve 开发环境
        //     // this.networkVersion = 56
        //     this.networkVersion = 56
        // }
            this.networkVersion = process.env.VUE_APP_CHAIN_ID
            const web3Utils = new this.$Web3(window.ethereum).utils

            window.localStorage.setItem('switchMT', 2)
            const injectedProvider = await this.getTrustWalletFromWindow();
            console.log(injectedProvider)
            if (injectedProvider == null || injectedProvider.isTokenPocket){
                this.$toast(this.$t('accounts.install'))
                return
            }
            let obj = null
            if(window.localStorage.getItem('walletAddress')){
                obj = JSON.parse(window.localStorage.getItem('walletAddress'))
            }
            // console.log(injectedProvider.address, obj)
            if(!injectedProvider.address || injectedProvider.address != obj){
                this.$store.commit("setwalletAddress", "");
                this.$store.commit("setDevotokens","");
                this.$store.commit("setLinkBsc", false);
                this.$storage.clear();
            }else if(obj){
                window.localStorage.setItem('switchMT', 2)
                this.$store.commit('setwalletAddress', JSON.parse(window.localStorage.getItem('walletAddress')));
            }
            injectedProvider.request({ method: "eth_chainId" }).then(res => {
                if (res != web3Utils.numberToHex(Number(this.networkVersion))) {
                // if (res != '0x38') {
                    //切换链ID
                    injectedProvider.request({
                        method: "wallet_switchEthereumChain",
                        params: [{
                            chainId: new this.$Web3(window.ethereum).utils.numberToHex(Number(this.networkVersion)),
                        }]
                    })
                }
            })
            injectedProvider.addListener("chainChanged", (id) => {
                if (id != web3Utils.numberToHex(Number(this.networkVersion))) {
                // if (id != '0x38') {
                    // this.$toast(this.$t('public.bsclogin')) //请使用BSC登入
                    //切换链ID
                    injectedProvider.request({
                        method: "wallet_switchEthereumChain",
                        params: [{
                            chainId: new this.$Web3(window.ethereum).utils.numberToHex(Number(this.networkVersion)),
                        }]
                    })
                }
            });
        // const injectedProvider = await this.getTrustWalletFromWindow();
        // if (injectedProvider) {
        // console.log(injectedProvider.address)
        //     const account = injectedProvider.address
        //     if (storage.get("walletAddress") !== ethereum.selectedAddress) {
        //         this.$store.commit("setwalletAddress", "");
        //         storage.remove("devotokens");
        //         this.$store.commit("setLinkBsc", false);
        //         storage.clear();
        //         if (this.$route.path == "/index") {
        //             this.reload()
        //         } else {
        //             this.$router.replace("/index")
        //         }
        //     }else{
        //         this.$store.commit('setwalletAddress', ethereum.selectedAddress); //钱包地址
        //     }
        // }
        // setTimeout(() => {
        //     this.check();
        //     this.start();
        // }, 100);
    },
    methods: {
        async getToto() {
            const { ethereum } = await window;
            const injectedProvider = await this.getTrustWalletFromWindow();
            this.injected(injectedProvider)
        },
        //TW钱包调用
        getTrustWalletFromWindow() {
            const isTrustWallet = (ethereum) => {
                // Identify if Trust Wallet injected provider is present.
                const trustWallet = !!ethereum.isTrust;
                return trustWallet;
            };
            const injectedProviderExist =
                typeof window !== "undefined" && typeof window.ethereum !== "undefined";

            if (!injectedProviderExist) {
                return null;
            }

            if (isTrustWallet(window.ethereum)) {
                return window.ethereum;
            }

            if (window.ethereum?.providers) {
                return window.ethereum.providers.find(isTrustWallet) ?? null;
            }
            return window["trustwallet"] ?? null;
        },
        async getInfoData() {
            const injectedProvider = await this.getTrustWalletFromWindow();
            if(injectedProvider == null || injectedProvider.isTokenPocket){
                this.$toast(this.$t('accounts.install'))
                return 
            }
            console.log(injectedProvider)
            if (injectedProvider == null) {

                this.$toast(this.$t('accounts.install'))
                return
                // window.localStorage.setItem('switchMT', 1)
                // this.check();
                // this.start();
                // setTimeout(() => {
                //     this.connectMetaMask()
                // }, 100);
            } else {
                window.localStorage.setItem('switchMT', 2)
                this.$store.commit('setLinkBsc', true);
                this.injected(injectedProvider)
            }
        },
        async injected(injectedProvider) {
            //发起连接
            const account = await injectedProvider.request({
                method: "eth_requestAccounts",
            });
            console.log(account)
            //获取账户
            let accounts = null;
            injectedProvider
                .request({
                    method: "eth_accounts",
                })
                .then(async (res) => {
                    accounts = res[0];
                    const provider = new ethers.providers.Web3Provider(injectedProvider);
                    const accountBalance = await provider.getBalance(accounts);

                    const signer = provider.getSigner();
                    this.getmsgT(accounts, signer); //获取签名消息
                    // console.log(accountBalance, 367);
                });
            //监听账户更改或断开事件
            injectedProvider.addListener("accountsChanged", (accounts) => {
                    this.$store.commit("setwalletAddress", "");
                    this.$store.commit("setDevotokens", "");
                    this.$store.commit("setLinkBsc", false);
                    this.$storage.clear();
                    // console.log(1111)
                    // this.reload()
            });

            // 获取链ID
            // const chainId = await injectedProvider.request({ method: "eth_chainId" });

            //监听链ID变化
            // injectedProvider.addListener("chainChanged", (id) => {
            //     console.log(id, 370); // => '0x1'
            // });
        },
        //获取签名数据
        getmsgT(e, signer) {
            let _this = this;
            this.$toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: "loading...",
            });
            this.$axios
                .post(this.$api.getmsg, {
                    address: e,
                })
                .then((res) => {
                    // console.log(res, e);
                    _this.getmsgIns(res.data.msg, e, signer);
                });
        },
        //请求签名
        async getmsgIns(msg, address, signer) {
            signer.signMessage(msg).then((result) => {
                // console.log(result);
                let url = window.location.href
                let code;
                if (url.indexOf('code=') != -1) {
                    url.split('?').forEach(item => {
                        if (item.indexOf('code=') != -1) {
                            code = item.split('=')[1]
                        }
                    })
                } else {
                    code = ''
                }
                this.$axios
                    .post(this.$api.Access, {
                        address: address,
                        signature: result,
                        code: code,
                    })
                    .then((res) => {
                        this.$toast.clear();
                        //钱包地址
                        // console.log(address)
                        this.$store.commit('setwalletAddress', address);
                        this.$storage.set('walletAddress', address)
                        this.$storage.set("devotokens", res.data.auth);
                        this.$axios.get(this.$api.myEarn, {}).then((res) => { //查询总资产
                            this.$store.commit("settotalAmount", res.data.totalAmount);
                            window.localStorage.setItem('totalAmount', res.data.totalAmount)
                        });
                        this.reload();
                    })
                    .catch((err) => {
                        console.error(err)
                        Toast('failure')
                    });
            }).catch(err => {
                console.error(err)
                this.$toast.clear();
            });
        },

        // 小狐狸检测钱包环境
        async check() {
            const { ethereum } = await window;
            if (!(ethereum && ethereum.isMetaMask)) {
                return this.$toast(this.$t('public.install'))
            } else {
                this.$store.commit('setwalletAddress', ethereum.selectedAddress); //钱包地址
                // this.global.account = ethereum.selectedAddress
                if (!ethereum.networkVersion) {
                    //退出
                    this.$store.commit("setwalletAddress", "");
                    this.$store.commit("setDevotokens", "");
                    this.$storage.remove("devotokens");
                    this.$store.commit("setLinkBsc", false);
                    this.$storage.clear();
                    this.$toast(this.$t('public.loginwallt')); //请登录钱包
                    this.reload();
                    //退出钱包后 跳到首页
                    setTimeout(() => {
                      this.$router.push("/index");
                    }, 1000);
                } else if (ethereum.networkVersion != this.networkVersion) {
                    this.$toast(this.$t('public.bsclogin')) //请使用BSC登入
                    this.$store.commit('setLinkBsc', false);
                    setTimeout(() => {
                        this.checkNet()
                    }, 200);
                } else {
                    this.$store.commit('setLinkBsc', true);
                }
            }
        },
        checkNet() {
            ethereum.request({
                method: "wallet_addEthereumChain",
                params: [{
                    chainId: new this.$Web3(window.ethereum).utils.numberToHex(Number(this.networkVersion)),
                    chainName: this.networkVersion === 56 ? "BSC主网" : "BSC测试网",
                    nativeCurrency: {
                        name: "tbnb",
                        symbol: "BNB",
                        decimals: 18
                    },
                    rpcUrls: this.networkVersion === 56 ? ['https://bsc-dataseed1.binance.org'] : ['https://data-seed-prebsc-1-s1.binance.org:8545'],
                    blockExplorerUrls: this.networkVersion === 56 ? ['https://bscscan.com'] : ['https://testnet.bscscan.com'],
                }]
            }).then(res => {
                this.reload()
            })
        },
        async start() {
            let _this = this
            if (storage.get("walletAddress")) {
                if (storage.get("walletAddress") !== ethereum.selectedAddress) {
                    this.$store.commit("setwalletAddress", "");
                    storage.remove("devotokens");
                    this.$store.commit("setLinkBsc", false);
                    storage.clear();
                    window.localStorage.setItem('switchMT', 1)
                    // console.log(this.$route.path);
                    if (this.$route.path == "/index") {
                        this.reload()
                    } else {
                        this.$router.replace("/index")
                    }
                } else {
                    // console.log(222222);
                }
            }
            // 监控账号切换
            window.ethereum.on("accountsChanged", function (item) {
                _this.$store.commit("setwalletAddress", "");
                storage.remove("devotokens");
                _this.$store.commit("setLinkBsc", false);
            });

            // 监控网络切换
            window.ethereum.on("chainChanged", (e) => {
                console.log('chainChanged', e)
                if (e == 56) {
                    this.$store.commit('setLinkBsc', true);
                } else {
                    this.$store.commit('setLinkBsc', false);
                    this.$toast(this.$t('public.bsclogin'))
                    setTimeout(() => {
                        this.checkNet()
                    }, 200);
                }
                this.reload()
            });
            //当收到特定消息时触发
            window.ethereum.on('message', (e) => {
                // console.log('message', e);
            })
        },

        // 链接钱包
        async connectMetaMask() {
            ethereum.request({
                method: 'wallet_requestPermissions', //wallet_requestPermissions请求弹出metaMask窗口
                params: [{ eth_accounts: {} }],
            }).then(async (permissions) => {
                console.log('permissions=>', permissions);
                if (permissions[0].parentCapability == "eth_accounts") {
                    if (ethereum.networkVersion != this.networkVersion) {
                        this.$toast(this.$t('public.bsclogin')) //请使用BSC登入
                        this.$store.commit('setLinkBsc', false);
                        setTimeout(() => {
                            this.checkNet()
                        }, 200);
                    } else {
                        this.$store.commit('setLinkBsc', true);
                    }
                    // const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
                    web3.eth.getAccounts().then((accounts) => { //获取账户列表
                        this.$store.commit('setwalletAddress', ethereum.selectedAddress);
                        storage.set('walletAddress', ethereum.selectedAddress)
                        this.getmsg(ethereum.selectedAddress)
                    });
                    // const accounts = ethereum.selectedAddress
                }
            })
        },
        //获取签名数据
        getmsg(e) {
            let _this = this
            this.$toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: "loading...",
            });
            this.$axios.post(this.$api.getmsg, {
                address: e,
            }).then(res => {
                _this.getmsgIn(res.data.msg, e)
            })
        },
        //请求签名
        async getmsgIn(msg, address) {
            this.$toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: "loading...",
            });
            // let result = await this.generateSign(address, msg)
            var text = msg;
            var msgs = ethUtil.bufferToHex(text)
            var from = address
            var params = [msgs, from]
            var method = 'personal_sign'
            web3.currentProvider.send({
                method,
                params,
                from,
            }, (err, result) => {
                if (err) {
                    console.error(err)
                    this.$toast.clear()
                    return '';
                }
                if (result.error) {
                    console.error(result.error);
                    return '';
                }
                // console.log('signresult=>', result.result);
                let url = window.location.href
                let code;
                if (url.indexOf('code=') != -1) {
                    url.split('?').forEach(item => {
                        if (item.indexOf('code=') != -1) {
                            code = item.split('=')[1]
                        }
                    })
                } else {
                    code = ''
                }
                // console.log(code);
                this.$axios.post(this.$api.Access, {
                    address: address,
                    signature: result.result,
                    code: code,
                }).then(res => {
                    // console.log('devotokens=>', res.data.auth);
                    this.$toast.clear()
                    storage.set('devotokens', res.data.auth)
                    this.$axios.get(this.$api.myEarn, {}).then((res) => { //查询总资产
                        this.$store.commit("settotalAmount", res.data.totalAmount);
                    });
                    this.reload()
                }).catch(err => {
                    Toast('failure')
                })
                // return result.result
            });

        },
    }
}