import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routerList = [];

function importAll(r){
  r.keys().forEach(
    (key) => {
      if(!!r(key).default){
        routerList.push(...r(key).default)
      }
    }
  )
}

importAll(require.context('./modules',true,/\.router\.js$/));

const routers = [
  ...routerList
]

export default routers
