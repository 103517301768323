import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const api = {};
function importAll(r) {
  r.keys().forEach((key) => {
    if (!!r(key).default) {
      for (const attr in r(key).default) {
        api[attr] = r(key).default[attr];
      }
    }
  });
}
importAll(require.context("./common", true, /\.api\.js/));

export default api;
