import axios from "axios";
import { Toast } from "vant";
import storage from "storejs";
import Vue from "vue";
import routes from '../router/index'
import VueRouter from "vue-router";
import myVue from "../main.js"
import store from "../store/index";
 
//添加请求拦截器
axios.interceptors.request.use(
  (config) => {
    //设置请求语言，可选注释
    // 中文  CN 英文 EN-US
    // this.$router.push('/index')
    config.headers.language = storage.get('lang') == 'zh' ? 'CN' : 'EN-US';
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

//添加响应拦截器
// axios.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     console.log(error)
//     return Promise.resolve(error.response);
//   }
// );
// axios.defaults.baseURL = "https://api.topbts.com/";//请求地址 线上
// axios.defaults.baseURL = "http://18.141.216.50:8970/";//请求地址 测试
// axios.defaults.baseURL = "http://192.168.1.13";//请求地址 本地
// if (process.env.NODE_ENV === 'production') {
//   /*第二层if，根据.env文件中的VUE_APP_FLAG判断是生产环境还是测试环境*/
//   if (process.env.VUE_APP_FLAG === 'pro') {
//     //production 生产环境 线上
//     axios.defaults.baseURL = 'https://api.unadapp.com';
//     // axios.defaults.baseURL = 'http://13.212.228.175:9002'
//   } else {
//     //test 测试环境
//     // axios.defaults.baseURL = 'https://api.clsdapp.com';
//     axios.defaults.baseURL = 'https://api.unadapp.com'
//     // axios.defaults.baseURL = 'http://13.212.228.175:9002'
//   }
// } else {
//   //serve 开发环境
//   // axios.defaults.baseURL = 'http://13.212.228.175:8990';
//   // axios.defaults.baseURL = 'http://192.168.1.7:8080';
//   // axios.defaults.baseURL = 'https://api.clsdapp.com';
//   // axios.defaults.baseURL = 'http://13.212.228.175:9002'
//   axios.defaults.baseURL = 'https://api.unadapp.com';
// }

axios.defaults.baseURL = process.env.VUE_APP_API_URL

axios.defaults.withCredentials = false; //设置后默认不带cookie
axios.defaults.timeout = 60000;

function checkStatus(response) {
  return new Promise((resolve, reject) => {
    if (response.status === 200) {

      if(response.data.code == 200){
        // window.localStorage.setItem('indexShow',false)
        resolve(response.data);
      }

      else if (response.data.code == 9002) {
        Toast(response.data.msg);
        storage.remove('devotokens')
      } 
      else if (response.data.code == 401) {
        // window.localStorage.setItem('indexShow',true)
        // Toast(response.data.msg)
        // storage.remove('devotokens')
        Toast(storage.get('lang') == 'zh' ? '请登录钱包' : 'Please log in to wallet');
        
        // vue.$router.push('/index')
        // routes.push('/')
        if(myVue.$route.path != '/index'){
          setTimeout(()=>{
            myVue.$router.push({path:"/index"})
          },1500)
        }
        // routes.forEach(itme,index => {
        //   console.log(itme)
        //   if(item. )
        // })
        resolve(response.data);
        // window.location.href = 'https://unadapp.com/#/index'
      }
      else {
        Toast(response.data.msg)
      }
      // resolve(response.data);
    } else {
      let { message } = response;
      if (message.includes("Network Error")) {
        message = storage.get('lang') == 'zh' ? "接口连接异常" : "Abnormal interface connection";
      } else if (message.includes("timeout")) {
        message = storage.get('lang') == 'zh' ? "接口请求超时" : "Interface request timeout";
      }
      Toast(message)
      reject(response);
    }
  });
}

export default {
  post(url, params) {
    // axios.defaults.baseURL = baseUrl;
    return axios({
      method: "post",
      url,
      data: params,
      //自定义请求内容
      headers: { Authorization: 'Bearer ' + storage.get('devotokens') },
    }).then((response) => {
      return checkStatus(response);
    }).catch((err) => {
      return checkStatus(err);
    });
  },
  get(url, params) {
    // params = qs.stringify(params);
    // axios.defaults.baseURL = baseUrl;
    return axios({
      method: "get",
      url,
      headers: { Authorization: 'Bearer ' + storage.get('devotokens') },
      params,
    }).then((response) => {
      return checkStatus(response);
    }).catch((err) => {
      console.log(err)
      return checkStatus(err);
    });
  },
  //调用七牛云上传
  uploadFile(file, token, observe) {
    file.status = "uploading";
    file.message = "上传中...";
    let key =
      md5(file.file.name.split(".")[0]) + "." + file.file.name.split(".")[1];
    let config = {
      useCdnDomain: true,
      region: qiniu.region.z0,
    };
    let putExtra = {
      fname: file.file.name,
      params: {},
      mimeType: ["image/png", "image/jpeg", "image/jpg"],
    };

    //开始上传  token 需要找后端获取(单独的方法)
    let observable = qiniu.upload(
      file.file,
      key,
      token,
      putExtra,
      config
    );
    observable.subscribe(observe);
  }
};
