<template>
  <div id="app">
    <!-- <keep-alive></keep-alive> -->
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
export default {
  provide() {
    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
    return {
      reload: this.reload,
    };
  },
  watch: {
    clientHeight() {
      //如果clientHeight 发生改变，这个函数就会运行
      this.changeFixed(this.clientHeight);
    },
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  mounted() {
    if (window.location.href.indexOf("code=") != -1) {
      // this.getCode()
    }
    // 获取可视化窗口高度
    this.clientHeight = `${document.documentElement.clientHeight}`; //获取浏览器可视区域高度
    let that = this;
    window.onresize = function () {
      this.clientHeight = `${document.documentElement.clientHeight}`;
      if (that.$refs.page) {
        that.$refs.page.style.minHeight = clientHeight + "px";
      }
    };

    if (typeof window.ethereum !== "undefined") {
      console.log("√ metamask");
    } else {
      console.log("× metamask");
    }
  },
  methods: {
    // 取邀请码
    getCode() {
      let geturl = window.location.href;
      let getinfo = geturl.split("?")[1];
      let code = getqys[0].split("=")[1];
      sessionStorage.setItem(code);
    },
    changeFixed(clientHeight) {
      if (this.$refs.page) {
        this.$refs.page.style.minHeight = clientHeight + "px";
      }
    },
    reload() {
      this.isRouterAlive = false; //先关闭，
      this.$nextTick(function () {
        this.isRouterAlive = true; //再打开
      });
    },
  },
};
</script>

<style lang="css">
#app {
  /* font-family: "ArialMT"; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #0d182a; */
  /* color: #fff; */
  width: 750rpx;
  min-height: 100vh;
  /* font-size: 13px; */
}
/*每个页面公共css 适配全面屏状态栏高度 */
.status_bar {
  height: var(--status-bar-height);
  width: 100%;
}
</style>
